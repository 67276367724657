import { Autocomplete, Box, List, ListItem, ListItemButton, ListItemText, Slider, TextField as MuiTextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../../components/Common/Button/Button';
import FilterOptions from '../submission/filterOptions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TextFields from '@mui/material/TextField';
import { TextField } from '@fluentui/react';

import DateSelect from '../../components/Common/dateSelect';
import TimeSelect from '../../components/Common/timeSelect';
dayjs.extend(customParseFormat);

interface ConfigModalProps {
    configArray: any[],
    currentConfig: number,
    subjectClick: (e: any, index: number, item: any) => void,
    addTestClick: () => void,
    subjectChange: any,
    subjectData: any,
    chapterChange: any,
    topicChange: any,
    seeAvailable: any,
    configChange: any,
    selectAllChapter: any,
    selectAllTopic: any,
    questionError: boolean,
    conFigSliderChange: any,
    setMaxNumber: any,
    selectDate: any,
    timeChange: any,
    closeConfig: any,
    saveClick: any
}

const ConfigModal: React.FC<ConfigModalProps> = ({ configArray, currentConfig, subjectClick, subjectData, addTestClick, subjectChange, topicChange, chapterChange, seeAvailable,
    configChange, selectAllChapter, selectAllTopic, questionError, conFigSliderChange, setMaxNumber, selectDate, timeChange, closeConfig, saveClick
}) => {

    const [validation, setValidation] = useState({ interval: false, numQuestions: false, testTime: false, correct: false });

    useEffect(() => {
        if (seeAvailable && !configArray[currentConfig].availablity?.Easy) {
            seeAvailable();
        }
    }, [])

    const handleSaveClick = () => {
        const current = configArray[currentConfig];
        const newValidation = {
            interval: !current.interval,
            numQuestions: !current.numQuestions,
            testTime: !current.testTime,
            correct: !current.correct,
        };
        setValidation(newValidation);

        if (!Object.values(newValidation).some(val => val)) {
            saveClick();
        }
    };



    return (
        <div className='config_body'>
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <div className='config_ul'>

                    <List>
                        {configArray.map((item: any, index: number) => {
                            return (
                                <ListItem disablePadding key={index}>
                                    <ListItemButton onClick={(event: any) => subjectClick(event, index, item)}
                                        selected={currentConfig === index}>
                                        <ListItemText primary={`${index + 1}. ${item.selectedSub?.name ? item.selectedSub?.name : ""}`} />
                                    </ListItemButton>
                                </ListItem>

                            )
                        })}

                    </List>
                    <div className='config_icon'>
                        <Button btnText="Add subject" onClick={addTestClick} />
                    </div>
                </div>
            </Box>
            <div className='config_edit_section'>
            <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={subjectChange}
                    options={subjectData}
                    key={currentConfig}
                    value={configArray[currentConfig].selectedSub || null}
                    getOptionLabel={(option: any) => option?.name || ""}
                    sx={{ minWidth: 300 }}
                    renderInput={(params) => (
                        <MuiTextField {...params} label="Select a subject" 
                        />
                    )}
                />
                {configArray[currentConfig].chapterData?.length ?
                    <div className='selectAllCheckbox'>
                        <FilterOptions
                            data={configArray[currentConfig].chapterData}
                            value={configArray[currentConfig].selectedChapter}
                            onChange={chapterChange}
                            name="name"
                            label="All Chapter"
                        />

                        <input checked={configArray[currentConfig].selectedChapter?.length === configArray[currentConfig].chapterData?.length} type="checkbox" onChange={selectAllChapter} />
                    </div>

                    : ""}
                {configArray[currentConfig].topicData?.length ?
                    <div className='selectAllCheckbox'>
                        <FilterOptions
                            data={configArray[currentConfig].topicData}
                            value={configArray[currentConfig].selectedTopic}
                            onChange={topicChange}
                            name="name"
                            label="All Topic"
                        />
                        <input checked={configArray[currentConfig].selectedTopic?.length === configArray[currentConfig].topicData?.length} onChange={selectAllTopic} type="checkbox" />
                    </div>

                    : ""}
                <Button btnText="See available question" className='availble_btn' onClick={seeAvailable} />
                <ul className='question_avail'>
                    <li>Available Questions.</li>
                    <li>Easy : {configArray[currentConfig].availablity?.Easy}</li>
                    <li>Medium : {configArray[currentConfig].availablity?.Medium}</li>
                    <li>Hard : {configArray[currentConfig].availablity?.Hard}</li>
                </ul>
                <div className="inline_label">
                    <MuiTextField
                        label="Interval (In days)"
                        placeholder="Interval"
                        className="loginField"
                        size="small"
                        name='interval'
                        required
                        value={configArray[currentConfig].interval}
                        onChange={(e: any) => configChange(e.target.value, "interval")}
                        error={validation.interval && !configArray[currentConfig].interval}
                    />
                </div>
                <div className="inline_label">
                    <div className='class_time'>
                        <DateSelect disablePast={true} onChange={selectDate} defaultValue={configArray[currentConfig]?.date} />
                    </div>
                    <div className='class_time'>
                        <TimeSelect onChange={timeChange} ampm={false} defaultValue={configArray[currentConfig].timeStamp ? configArray[currentConfig].timeStamp : undefined} />
                    </div>
                </div>
                <div className="inline_label" style={{ alignItems: "self-start" }}>
                    <MuiTextField
                        label="No of question"
                        placeholder="No. of question"
                        className="loginField"
                        name='numQuestions'
                        required
                        size="small"
                        error={(validation.numQuestions && !configArray[currentConfig].numQuestions) || questionError}
                        helperText={(questionError ? "Please see availability first" : "")}
                        value={configArray[currentConfig].numQuestions}
                        onChange={(e: any) => configChange(e.target.value, "numQuestions")}
                    />
                    <MuiTextField
                        label="Test time (In Minutes)"
                        placeholder="Test Time"
                        className="loginField"
                        name='testTime'
                        required
                        size="small"
                        error={validation.testTime && !configArray[currentConfig].testTime}
                        value={configArray[currentConfig].testTime}
                        onChange={(e: any) => configChange(e.target.value, "testTime")}
                    />

                </div>
                <div className="inline_label">
                    <MuiTextField
                        label="Correct"
                        placeholder="Correct"
                        className="loginField"
                        name='correct'
                        required
                        size="small"
                        error={validation.correct && !configArray[currentConfig].correct}
                        value={configArray[currentConfig].correct}
                        onChange={(e: any) => configChange(e.target.value, "correct")}
                    />
                    <MuiTextField
                        label="Wrong"
                        placeholder="Wrong"
                        className="loginField"
                        name='wrong'
                        required
                        size="small"
                        value={configArray[currentConfig].wrong}
                        onChange={(e: any) => configChange(e.target.value, "wrong")}
                    />
                </div>
                <div className='level_head slider_level'>
                    <label className='time_label' htmlFor="">Easy</label>
                    <Slider
                        size="small"
                        onChange={(e: any, val: any, data: any) => conFigSliderChange(e, val, data, "easy")}
                        max={setMaxNumber("Easy")}
                        valueLabelFormat={`${configArray[currentConfig].easy} out of ${setMaxNumber("Easy")}`}
                        valueLabelDisplay="on"
                        value={configArray[currentConfig].easy}
                        aria-label="Small"
                    />
                </div>
                <div className='level_head slider_level'>
                    <label className='time_label' htmlFor="">Medium</label>
                    <Slider
                        size="small"
                        value={configArray[currentConfig].medium}
                        aria-label="Small"
                        valueLabelFormat={`${configArray[currentConfig].medium} out of ${setMaxNumber("Medium")}`}
                        onChange={(e: any, val: any, data: any) => conFigSliderChange(e, val, data, "medium")}
                        max={setMaxNumber("Medium")}
                        valueLabelDisplay="on"

                    />
                </div>
                <div className='level_head slider_level'>
                    <label className='time_label' htmlFor="">Hard</label>
                    <Slider
                        size="small"
                        max={setMaxNumber("Hard")}
                        valueLabelFormat={`${configArray[currentConfig].hard} out of ${setMaxNumber("Hard")}`}
                        value={configArray[currentConfig].hard}
                        onChange={(e: any, val: any, data: any) => conFigSliderChange(e, val, data, "hard")}
                        aria-label="Small"
                        valueLabelDisplay="on"
                    />
                </div>
                <div className='config_footer'>
                    <button className='cancel_btn' onClick={closeConfig}>Close</button>
                    <button className='update_btn' onClick={handleSaveClick}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default ConfigModal;