import React from 'react';
import { dateFormat, getRandomColor } from '../../../utils/CommonFunction';
import { Tooltip } from 'antd';
import { Icon } from '@fluentui/react';
import { Link } from 'react-router-dom';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PopoverComp from '../popoverComp';
import { useSelector } from 'react-redux';

interface CourseBoxProps {
    editStatus: boolean;
    item: any;
    editClick?: (item: any, e: React.MouseEvent<HTMLElement>) => void;
    publishClick?: (item: any, e: React.MouseEvent<HTMLElement>, status: number) => void;
    classClick: (item: any, name: string, department?: any) => void;
    deleteClick?: (item: any, name: string, e: React.MouseEvent<HTMLElement>) => void;
    analyticClick?: (item: any, name: string, e: React.MouseEvent<HTMLElement>) => void;
    name: string;
    popoverContent?: any;
    showPublish?: boolean;
    scheduleStartTime?: any;
    scheduleEndTime?: any;
    scheduleEdit?: (e: any, item: any) => void;
    classes: string
}

const CourseBox: React.FC<CourseBoxProps> = ({ editStatus, item, editClick, classClick, name, deleteClick, publishClick, showPublish, popoverContent, scheduleStartTime, scheduleEndTime, scheduleEdit, analyticClick, classes }) => {

    const userRole = useSelector((state: any) => state.ui.userMetadata)

    const showClassName = (id: number[]) => {

    }

    return (
        <div key={item.courseId} className="subject" onClick={() => classClick(item, name)} style={{ background: getRandomColor(item.courseName).color }}>
            {userRole.role !== "Student" ?
                <div className="dateAndMore">
                    <div className="facePileAndMore">
                        <div className="subjectFacePile">
                            {(editStatus && editClick) ?
                                <Tooltip title="Edit course">
                                    <Icon
                                        iconName="Edit"
                                        className="analyticDlt"
                                        onClick={(e: React.MouseEvent<HTMLElement>) => editClick(item, e)}
                                    ></Icon>
                                </Tooltip> : null}
                            {(showPublish && publishClick) ?
                                <Tooltip title={`${item.publishStatus === 2 ? "Course is Private" : "Course has been published"}`}>
                                    {item.publishStatus === 2 ?
                                        <PopoverComp showTool={true} text="Course is Private" placement="left" content={popoverContent}>
                                            <UnpublishedIcon onClick={(e: any) => publishClick(item, e, item.publishStatus)} />
                                        </PopoverComp> : <PublishedWithChangesIcon onClick={(e: any) => publishClick(item, e, item.publishStatus)} />}
                                </Tooltip> : null}
                            {(editStatus && deleteClick) ?
                                <Tooltip title="Delete course">
                                    <Icon
                                        className="analyticDlt"
                                        iconName="Trash"
                                        onClick={(e: React.MouseEvent<HTMLElement>) => deleteClick(item, name, e)}
                                    ></Icon>
                                </Tooltip> : null}
                            {((item.courseType === "Test") && analyticClick) ?
                                <Tooltip title="View Analytics">
                                    <Icon
                                        className="analyticDlt"
                                        iconName="BarChartVertical"
                                        onClick={(e: React.MouseEvent<HTMLElement>) => analyticClick(item, name, e)}
                                    ></Icon>
                                </Tooltip>
                                : null}
                        </div>
                    </div>
                </div> : ""}
            <div className="subjectDetails">
                <h2 className="boxHeading" style={{ fontWeight: "500" }}>
                    {item.courseName}
                </h2>
                <p className="teacherName"><p>Created By </p> : <Tooltip title={item?.CreatorFullName}><p className="tooltipText">{item?.CreatorFullName}</p></Tooltip></p>
                <p className="teacherName"><p> Class </p> : <Tooltip title={classes}><p className="tooltipText">{classes}</p></Tooltip></p>
                <p className="teacherName">Course Code : {item.courseCode}</p>
                <p className="teacherName">Course Type : {item.courseType}</p>
                {(scheduleStartTime && scheduleEdit) ? <p onClick={(e: any) => scheduleEdit(e, item)} className="teacherName scheduledTime" >Scheduled Time : {scheduleStartTime}</p> : ""}
                {scheduleEndTime ? <p className="teacherName" >Scheduled Close Time : {scheduleEndTime}</p> : ""}
                {item.courseType === "Regular" ? (
                    ""
                ) : (
                    <p className="teacherName">
                        Attempts : {item.allowedAttempts ? item.allowedAttempts : 0}
                    </p>
                )}
                <p className="teacherName">Last Modified : {dateFormat(item.lastModifiedDate)}</p>
                <p className="teacherName">Students Attended : {item.studentsAttended}</p>
                <div className="gradeDisplayName">
                    {item.displayName}
                </div>

            </div>
        </div>
    );
};

export default CourseBox;