import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs';
import React from 'react';

interface TimeSelectProps {
  // Define your props here
  onChange: (e: any, timeString: any) => void;
  defaultValue?: any,
  ampm?: boolean
}

const TimeSelect: React.FC<TimeSelectProps> = ({ onChange, defaultValue, ampm = true }) => {


  const defaultTime = dayjs(defaultValue, 'HH:mm');
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MobileTimePicker']} >
        <MobileTimePicker slotProps={{
          textField: {
            size: "small", // Set the TextField size to small
          },
        }} className='time_picker' value={(defaultValue && defaultValue !== "Invalid Date") ? defaultTime : null}
          label="Time" ampm={ampm} ampmInClock={false} onChange={(e: any) => onChange(e, e.format('HH:mm'))}
          defaultValue={(defaultValue && defaultValue !== "Invalid Date") ? dayjs(defaultTime, "HH:mm") : null} autoFocus={true} />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default TimeSelect;