import React, { useEffect, useState } from 'react';
import CourseBox from '../../components/Common/courseBox';
import "./index.css"
import { convertStamptoDate, convertTimeStamp, searchArray, searchOnlyParent } from '../../utils/CommonFunction';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Common/Button/Button';
import { DefaultButton, IComboBox, IComboBoxOption, IComboBoxStyles, Icon, PrimaryButton } from '@fluentui/react';
import { addedDeletedData, checkAi, getRandomColor } from '../../utils/CommonFunction';
import { GetCoursePagesNew, addCourse, deleteCourse, editCourse, getStudentCourses, getUserProfile, updatePublishStatus } from '../../redux/actions/API/courses';
import { getDepartmentClasses } from '../../redux/actions/API/departmentClasses';
import CommonModal from '../../components/commonModal/CommonModal';
import { useFormik } from 'formik';
import { addCourseSchema } from '../../utils/validationSchema';
import { setAppLocation, setScreenProps } from '../../redux/actions/UI/appLocation';
import { useNavigate } from 'react-router-dom';
import { courses, creatorStudio } from '../../utils/routePath';
import { courseInitialValue } from '../../utils/formikInitial';
import AddEditCourse from '../course/courseModalContent/addEditCourse';
import { DeleteModalContent } from '../course/courseModalContent/deleteModal';
import { Tooltip } from 'antd';
import ScheduleModal from '../../components/Common/scheduleModal';
import { PublishNowModal, UnPublishModal } from '../course/courseModalContent/publishNowModal';
import { Button as MuiButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
interface DashboardProps {
  // Define your props here
}

const Dashboard: React.FC<DashboardProps> = () => {
  const userRole = useSelector((state: any) => state?.ui?.userMetadata)
  const departmentData = useSelector((state: any) => state.api.getDepartmentClasses?.data) || []
  const allCoursesData = useSelector((state: any) => state.api.getStudentCourses?.data?.courses) || []
  const screenpropsData = useSelector((state: any) => state?.ui?.screenProps?.data?.Homeworks) || {}

  const [editStatus, setEditStatus] = useState<boolean>(false)
  const [allCourseStatus, setAllCourseStatus] = useState<boolean>(false)

  const [allDepartmentCourseData, setAllDepartmentCData] = useState<any[]>(departmentData)
  const [searchValue, setSearchValue] = useState<string>("")
  const [departmentCourseData, setDepartmentCData] = useState<any[]>(departmentData)

  const [allCourse, setAllCourse] = useState<any[]>(allCoursesData)
  const [allStudentCourse, setAllStudentCourse] = useState<any[]>(allCoursesData)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [currentEditData, setCurrentEditData] = useState<any>({})
  const [courseModalName, setCourseModalName] = useState<string>("")
  const [modalName, setModalName] = useState<string>("")
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [currentCourseData, setCurrentCourseData] = useState<any>({})
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  const [selectedTime, setSelectedTime] = useState<any>("")
  const [selectedMinute, setSelectedMinute] = useState<any>("")
  const [publishWithResultStatus, setPublishWithResultStatus] = useState<string>("")

  const [isJee, setIsJee] = useState<boolean>(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const editCourseBtnClick = () => {
    setEditStatus(!editStatus)
  }

  const { handleChange, setFieldValue, handleSubmit, handleBlur, resetForm, values, errors, touched } = useFormik({
    initialValues: courseInitialValue,
    validationSchema: addCourseSchema,
    onSubmit: () => submitCourse(),
  });

  const submitCourse = () => {
    const data = {
      departments: values.departments,
      correctScoring: values.correctScoring,
      wrongScoring: values.wrongScoring,
      testTime: values.testTime,
    }
    const stringData = JSON.stringify(data)
    if (courseModalName === "Add Course") {
      const payload = {
        data: {
          courseName: values.courseName,
          courseCode: values.courseCode,
          courseType: values.courseType,
          courseDataJson: stringData,
          classIds: values.classIds,
          teacherIds: values.teacherIds,
          courseDescription: values.courseDescription,
          publishStatus: values.publishStatus,
          allowedAttempts: values.allowedAttempts,
          file: null,
        },
        classId: 0
      }
      dispatch(addCourse(payload))
    }
    else if (courseModalName === "Edit Course") {
      const addedClass = addedDeletedData(values.classIds, currentEditData.classIds)
      const deletedClass = addedDeletedData(currentEditData.classIds, values.classIds)
      const addedTeacher = addedDeletedData(values.teacherIds, currentEditData.teacherIds)
      const deletedTeacher = addedDeletedData(currentEditData.teacherIds, values.teacherIds)
      const payloadUpdate = {
        data: {
          courseId: currentEditData.courseId,
          courseName: values.courseName,
          courseCode: values.courseCode,
          courseDescription: values.courseDescription,
          courseDataJson: stringData,
          publishStatus: "2",
          addedClassIds: addedClass,
          deletedClassIds: deletedClass,
          addedTeacherIds: addedTeacher,
          deletedTeacherIds: deletedTeacher,
        },
        classId: 0
      }
      dispatch(editCourse(payloadUpdate))
    }
    handleClose()
  }

  useEffect(() => {
    dispatch(getDepartmentClasses());
    dispatch(getStudentCourses());
  }, [])

  useEffect(() => {
    setAllCourse(allCoursesData)
  }, [allCoursesData])

  useEffect(() => {
    if (departmentData.length) {
      setDepartmentCData(departmentData)
      setAllDepartmentCData(departmentData)

      if (departmentData.length) {
        const data = departmentData[0]?.classes.map((item: any, index: number) => {
          if (index === 0) {
            item.selected = true
          } else {
            item.selected = false
          }
          return item
        })
        if (data.length) {
          setFieldValue("class", data[0].classId)
        }
      }
    }
  }, [departmentData, allCoursesData])

  const allCoursesClick = () => {
    if (allCourseStatus) {
      setEditStatus(false)
    }
    setSearchValue("")
    setAllCourse([...allStudentCourse])
    setDepartmentCData([...allDepartmentCourseData])
    setAllCourseStatus(!allCourseStatus)
  }
  const editClick = (item: any, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setCourseModalName("Edit Course")
    setCurrentEditData(item)
    setFieldValue("allowedAttempts", item.allowedAttempts)
    setFieldValue("classIds", item.classIds)
    setFieldValue("courseName", item.courseName)
    setFieldValue("courseCode", item.courseCode)
    setFieldValue("courseType", item.courseType)
    setFieldValue("teacherIds", item.teacherIds)
    setFieldValue("publishStatus", item.publishStatus)
    setFieldValue("courseDescription", item.courseDescription)
    const courseData = JSON.parse(item.courseDataJson)
    setFieldValue("correctScoring", courseData.correctScoring)
    setFieldValue("wrongScoring", courseData.wrongScoring)
    setFieldValue("testTime", courseData.testTime)
    setFieldValue("departments", courseData.departments)
    setEditModal(true)
    setCurrentCourseData(item)
  }
  const handleClose = () => {
    setEditModal(false)
    setModalOpen(false)
    resetForm()
  }


  const classClick = (item: any, name: string, department: any) => {
    if (name === "All Course") {
      dispatch(GetCoursePagesNew({ courseId: item.courseId, auto: checkAi(item.courseCode) }))
      dispatch(setAppLocation({ pathname: `/creator-studio/${item.courseId}/${item.courseType}/${item.courseName}/${item.courseCode}/${item.courseDescription}/${item.courseDataJson ? JSON.parse(item.courseDataJson).testTime : 60}` }))
      navigate(creatorStudio)
    } else {
      dispatch(setScreenProps({
        data: {
          Homeworks: {
            classId: item.classId,
            departmentId: department.departmentId
          }
        }
      }))
      navigate(courses)
    }
  }
  const deleteClick = (item: any, name: string, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setModalName("Delete Course")
    setCurrentEditData(item)
    setModalOpen(true)
    setCurrentCourseData(item)
  }

  const addCourseModalClick = (name: string, item?: any) => {
    setCourseModalName("Add Course")
    setCurrentEditData(item)
    setEditModal(true)
    setFieldValue("departments", [departmentData[0].departmentId])
    const currentClass = departmentData[0].classes
    setFieldValue("classIds", [currentClass[0].classId])
  }
  const publishClick = (item: any, e: React.MouseEvent<HTMLElement>, status: number) => {
    e.stopPropagation()
    if (status === 2) {

    } else {
      setModalName('Unpublish')
      setModalOpen(true)
    }
    setCurrentCourseData(item)
  }

  const publishNowClick = (item: any, e: any, type: string) => {
    e.stopPropagation()
    setModalName(type)
    setModalOpen(true)
  }
  const scheduleClick = (e: any, type: string) => {
    e.stopPropagation()
    setModalName(type)
    setModalOpen(true)
  }

  const popoverContent = <div className="publishPop">
    <ul>
      <MuiButton variant="outlined" onClick={(e: any) => publishNowClick(currentCourseData, e, "Publish Now")}>Publish Now</MuiButton>
      <MuiButton variant="outlined" onClick={(e: any) => scheduleClick(e, "Scheduled")}>Schedule Time</MuiButton>
    </ul>
  </div>
  useEffect(() => {
    dispatch(getUserProfile({ userId: userRole.id }))
  }, [])
  const confirmDlt = () => {
    dispatch(deleteCourse({
      courseId: currentEditData.courseId,
      classId: currentEditData.classIds[0]
    }))
    handleClose()
  }

  const confirmCourseClick = (e: any) => {
    e.preventDefault()
    handleSubmit()
  }
  const courseChange = (e: any, val: any) => {
    setFieldValue("courseType", val)
  }

  const jeeChange = (e: any) => {
    setIsJee(e)
  }

  const selectDate = (e: any, timeString: any) => {
    setSelectedDate(timeString)
  }
  const selectTime = (e: any, timeString: any) => {
    setSelectedTime(timeString)
  }

  const minuteChange = (e: any) => {
    setSelectedMinute(e.target.value)
  }

  const showScheduledDate = (data: string, activeTime?: string) => {
    if (data && data !== "null") {
      const array = data.split(" ")
      if (array[1] !== "NaN") {
        if (activeTime) {
          return convertStamptoDate(array[1], array[2])
        } else {
          return convertStamptoDate(array[1])
        }
      } else {
        return ""
      }
    } else {
      return ""
    }
  }
  const getDateAndTimeArray = (elem: any) => {
    const taskValue = elem.task
    if (taskValue && taskValue !== "null") {
      const dateArray = taskValue.split(" ")
      const dateAndTime = convertStamptoDate(dateArray[1]).split(", ")
      setSelectedDate(dateAndTime[0])
      setSelectedTime(dateAndTime[1])
      setSelectedMinute(dateAndTime[2])
    }
  }
  const scheduleEdit = (e: any, item: any) => {
    e.stopPropagation()
    getDateAndTimeArray(item)
    setModalName("Scheduled")
    setCurrentCourseData(item)
    setModalOpen(true)
  }

  const changeStatus = (e: any) => {
    setPublishWithResultStatus(e)
  }

  const modalContent = () => {
    if (modalName === "Delete Course") {
      return <DeleteModalContent {...{ confirmDlt, handleClose, modalName }} />
    } else if (modalName === "Scheduled") {
      return <ScheduleModal
        {...{ selectedDate, selectedTime, minuteChange }}
        minValue={selectedMinute}
        selectDate={(e: any, timeString: any) => selectDate(e, timeString)}
        selectTime={(e: any, timeString: any) => selectTime(e, timeString)}
        taskValue={currentCourseData.task}
        handleChange={changeStatus}
      />
    } else if (modalName === "Publish Now") {
      return <PublishNowModal />
    } else if (modalName === "Unpublish") {
      return <UnPublishModal />
    }
  }

  const applyPublishStatus = (value: any) => {
    if (modalName === "Scheduled") {
      if (publishWithResultStatus) {
        return publishWithResultStatus
      } else {
        return "1"
      }
    }
    else {
      if (value == 1 || value == 3) {
        return "2"
      } else if (value == 2) {
        return "1"
      }
    }
  }

  const confirmPublishClick = async () => {
    const publishStatus = currentCourseData.publishStatus
    const courseId = currentCourseData.courseId
    const taskData = `${courseId} ${convertTimeStamp(selectedDate, selectedTime)} ${selectedMinute} ${applyPublishStatus(publishStatus)}`
    const updateData: any = {
      publishData: {
        CourseId: courseId,
        PublishStatus: applyPublishStatus(publishStatus),
        Task: modalName === "Scheduled" ? taskData : null
      },
      analyticData: {
        courseId: courseId,
        classId: screenpropsData.classId
      },
      name: "updateClass"
    }
    dispatch(updatePublishStatus(updateData))
    setModalName("")
    setModalOpen(false)

  }

  const showAddCourseOptions = () => {
    if (allCourseStatus && departmentCourseData.length) {
      const classLength = departmentCourseData.findIndex((item: any) => item.classes.length)
      if (classLength >= 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const showClassName = (ids: number[]) => {
    const classData = departmentData?.map((item: any) => item.classes).flat()
    const classesNames = classData?.filter((item: any) => ids.includes(item.classId)).map((val: any) => val?.classGrade)
    return classesNames.toString()
  }

  const searchChange = (e: any) => {
    const searchQuery = e.target.value
    if (searchQuery) {
      if (!allCourseStatus) {
        const filteredData = searchArray(allDepartmentCourseData, searchQuery)
        setDepartmentCData([...filteredData])
      } else {
        const filteredData = searchOnlyParent(allStudentCourse, searchQuery)
        setAllCourse([...filteredData])
      }
    } else {
      if (!allCourseStatus) {
        setDepartmentCData([...allDepartmentCourseData])
      } else {
        setAllCourse([...allStudentCourse])
      }
    }
    setSearchValue(e.target.value)
  }

  return (
    <>
      <CommonModal modalOpen={editModal} handleClose={handleClose} className="add_course_modal">
        <AddEditCourse isEditableCourse={editStatus} onSubmit={confirmCourseClick}  {...{ values, touched, handleClose, errors, handleChange, handleBlur, setFieldValue, courseChange, jeeChange, isJee }} labelText={courseModalName} />
        {/* <div className="footerBtn">
          <PrimaryButton
            onClick={confirmCourseClick}
            text={editStatus ? "Update" : "Add"}
          />
          <DefaultButton
            onClick={handleClose}
            text={editStatus ? "Cancel" : "No"}
          />
        </div> */}
      </CommonModal>

      <CommonModal handleClose={handleClose} modalOpen={modalOpen} className="publish-popup">
        {modalContent()}
        {modalName !== "Delete Course" ?
          <div className="footerBtn">
            <PrimaryButton
              onClick={confirmPublishClick}
              text="Yes"
            />
            <DefaultButton
              onClick={handleClose}
              text="No"
            />
          </div> : ""}
      </CommonModal>

      <div className="page_header">
        <div className="refresh_with_time header_with_search">
          <SearchIcon />
          <TextField value={searchValue} className='search_field' onChange={(e: any) => searchChange(e)} size="small" id="outlined-basic" label="Search here" variant="outlined" />
          {allCourseStatus ?
            <Button btnText={
              <>
                <Icon
                  iconName="Edit"
                  className="homeworkIcons"
                  title="Edit Course"
                />
                <span> {editStatus ? "View" : "Edit"}</span>
              </>

            } onClick={editCourseBtnClick}
              className={`${editStatus ? "buttonWithicon activeEditAllCourse" : " buttonWithicon enableEditAllCourse"}`}
              disabled={false} /> : null}
          <Button btnText={
            <span>All Courses</span>
          }
            onClick={allCoursesClick}
            className={`${allCourseStatus ? "buttonWithicon activeEditAllCourse" : " buttonWithicon enableEditAllCourse"}`}
            disabled={false} />

        </div>
      </div>
      <div className="homeworkElementsContainer dashboard_home">
        {showAddCourseOptions() ?
          <div
            className="addSubject"
            style={{ background: "#E6E6E6", cursor: "pointer", maxHeight: "100px" }}
            onClick={() => addCourseModalClick("Add Course")}

          >
            <Tooltip title="Add Course">
              <div className="addNewSubject">
                <Icon iconName="add" className="addSubjectIcon" />
                <p className="add_course_text">Add</p>
              </div>
            </Tooltip>
          </div> : null}
        <div className="departments">

          {!allCourseStatus ? (departmentCourseData.length ? departmentCourseData?.map((department: any, index: number) => {
            return (
              <div key={index} className="classesBody">
                <div className="department">
                  <h3>{department.deptName}</h3>
                </div>
                <div className="classContainer">
                  {department.classes.map((item: any, ind: number) => {
                    return (
                      <div key={ind} className="class" onClick={() => classClick(item, "Single", department)} style={{ background: getRandomColor(item.classGrade).color }}>
                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                          <div className="gradeName">{item.classGrade} - {item.section}</div>
                        </div>
                        <div className="gradeDisplayName">
                          {item.displayName}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          }) : "") :
            <div className="allCourses">
              {allCourse?.map((item: any, i: number) => {
                return (
                  <CourseBox classes={showClassName(item?.classIds)} key={'course' + i}  {...{ editClick, deleteClick, publishClick, classClick, editStatus, item, scheduleEdit, popoverContent }} scheduleEndTime={showScheduledDate(item.task, "activeTime")} scheduleStartTime={showScheduledDate(item.task)} showPublish={true} name="All Course" />
                )
              })}
            </div>}
        </div>
      </div>
    </>

  );
};

export default Dashboard;