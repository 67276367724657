import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import HelpAccordion from './helpAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { getHelp } from '../../redux/actions/API/courses';
import { Fragment } from 'react';
import Fuse from 'fuse.js';


interface HelpSectionProps {
    helpOpen: boolean;
    setHelpOpen?: any;
}
const HelpSection: React.FC<HelpSectionProps> = ({ helpOpen, setHelpOpen }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [results, setResults] = useState<{ key: string; content: string }[]>([]);
    const dispatch = useDispatch()
    const getHelpData = useSelector((state: any) => state.api?.getHelp?.data) || {}

    useEffect(() => {
        const dataArray = Object.keys(getHelpData).filter(key => key !== "Add New Tab").map((key) => ({ key, content: getHelpData[key] }));

        const fuse = new Fuse(dataArray, {
            keys: ['key', 'content'],
            includeScore: true,
            threshold: 0.3,
        });

        if (searchTerm) {
            const fuzzyResults = fuse.search(searchTerm);
            setResults(fuzzyResults.map(result => result.item));
        } else {
            setResults(dataArray)
        }
    }, [searchTerm]);
    const toggleDrawer = () => {
        setHelpOpen(false)
    }

    useEffect(() => {
        dispatch(getHelp())
    }, [])

    const highlightText = (text: string, search: string) => {
        if (!search) return text; // If there's no search term, return original text
        const regex = new RegExp(`(${search})`, 'gi'); // Create a regex to match the search term, case insensitive
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === search.toLowerCase() ?
                <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> :
                part
        );
    };

    const highlightHTMLContent = (htmlContent: string, search: string) => {
        if (!search) return htmlContent; // If there's no search term, return original HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent; // Create a temporary div to hold the HTML
        const regex = new RegExp(`(${search})`, 'gi');
        const traverseNodes: any = (node: ChildNode) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const matches = node.nodeValue?.split(regex);
                if (matches) {
                    const highlighted = matches.map((part, index) =>
                        part.match(regex) ?
                            <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> :
                            part
                    );
                    return highlighted;
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                const children: any = Array.from(node.childNodes).map(traverseNodes);
                return React.createElement(node.nodeName.toLowerCase(), { key: node.nodeName }, ...children);
            }
            return null;
        };

        const highlightedContent = Array.from(tempDiv.childNodes).flatMap(traverseNodes);
        return highlightedContent;
    };




    return (
        <div>

            <Fragment >
                <Drawer
                    anchor="right"
                    open={helpOpen}
                    onClose={toggleDrawer}
                >
                    <Box
                        sx={{ width: 550, padding: "15px" }}
                        role="presentation"
                    >
                        <div>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                className='fussy_input'
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div>
                                {results.map((item: any, index: number) => (
                                    <HelpAccordion index={index} title={highlightText(item.key, searchTerm)} searchTerm={searchTerm} content={highlightHTMLContent(item.content, searchTerm)} />
                                ))}
                            </div>
                        </div>
                    </Box>

                    {/* Contact Us Section */}
                    <div 
                        style={{
                        position: 'absolute',
                        bottom: '15px',
                        width: '100%',
                        textAlign: 'center'
                        }}
                    >
                        contact us at <b>contactwebodhi@gmail.com</b>
                    </div>
                </Drawer>
            </Fragment>

        </div>
    );
}


export default HelpSection
