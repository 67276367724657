import React, { useEffect, useState } from 'react';
import Button from '../../components/Common/Button/Button';
import { Icon, TextField } from '@fluentui/react';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch, useSelector } from 'react-redux';
import { classSchema, departmentSchema, teacherSchema } from '../../utils/validationSchema';
import { useFormik } from 'formik';
import CommonModal from '../../components/commonModal/CommonModal';
import { addClass, addDepartment, deleteClass, deleteDepartment, editClass, editConfig, editDepartment } from '../../redux/actions/API/departmentClasses';
import DepartmentBody from './dtManagerComp/departmentBody';
import TeacherBody from './dtManagerComp/teacherBody';
import "./index.css"
import { deleteClassModalData, deleteDepartmentModalData } from '../../utils/content';
import { deleteUser, getTeacherCourses } from '../../redux/actions/API/teacherCourses';
import { initialClass, initialDepartment, initialTeacher } from '../../utils/formikInitial';
import {
    DatePicker,
    defaultDatePickerStrings,
} from "@fluentui/react/lib/DatePicker";
import moment from 'moment';
import "../course/index.css"
import { convertSubjectData, dandTInTimestamp, encryption, openNotification, timeFormat, timestampToDateandTime } from '../../utils/CommonFunction';
import { getTopics, updateUserprofile } from '../../redux/actions/API/courses';
import { registerUser } from '../../redux/actions/API/login';
import { useNavigate } from 'react-router-dom';
import ImportModalData from '../../components/Common/ImportModalData';
import { actionLoader } from '../../redux/actions/UI/spinnerStatus';
import ClipboardModal from '../../components/Common/clipboardModal';
import { postAPiCall } from '../../API/Baseurl';
import Swal from 'sweetalert2';
import { Menu } from '../../utils/ImagePath';
import ConfigModal from './configModal';
import dayjs from 'dayjs';

interface DtManagerProps {
    // Define your props here
}
interface configInterface {
    subjectId: number | null,
    chapter: any[],
    topic: any[],
    interval: any,
    date: any,
    time: any,
    timeStamp: any,
    numQuestions: any,
    testTime: any,
    correct: any,
    wrong: any,
    easy: any,
    medium: any,
    hard: any,
    selectedSub: any,
    chapterData: any[],
    topicData: any[],
    selectedChapter: any[],
    selectedTopic: any[],
    availablity: any
}

const initialConfigData = {
    subjectId: null,
    chapter: [],
    topic: [],
    interval: "",
    date: moment().format('YYYY-MM-DD'),
    time: "",
    timeStamp: "",
    numQuestions: 0,
    testTime: "",
    correct: "",
    wrong: 0,
    easy: 0,
    medium: 0,
    hard: 0,
    selectedSub: {},
    chapterData: [],
    topicData: [],
    selectedChapter: [],
    selectedTopic: [],
    availablity: {}
}
const defaultConfigData = {
    subjectId: null,
    chapter: [],
    topic: [],
    interval: "",
    time: "",
    timeStamp: "",
    date: moment().format('YYYY-MM-DD'),
    numQuestions: 0,
    testTime: "",
    correct: "",
    wrong: 0,
    easy: 0,
    medium: 0,
    hard: 0,
    selectedSub: {},
    chapterData: [],
    topicData: [],
    selectedChapter: [],
    selectedTopic: [],
    availablity: {}
}



const DtManager: React.FC<DtManagerProps> = (props) => {
    const departmentData = useSelector((state: any) => state.api.getDepartmentClasses?.data) || []
    const getTopicData = useSelector((state: any) => state?.api?.getTopics?.data)
    const teacherData = useSelector((state: any) => state.api.getTeacherCourses?.data) || []
    const token = useSelector((state: any) => state?.api?.login?.data?.token)
    const [subjectData, setSubjectData] = useState<any[]>([])
    const [editStatus, setEditStatus] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<string>("Departments")
    const [currentTab, setCurrenttab] = useState<number>(0)
    const [departmentCourseData, setDepartmentData] = useState<any[]>(departmentData)
    const [classModal, setClassModal] = useState<boolean>(false)
    const [departmentModal, setDepartmentModal] = useState<boolean>(false)
    const [currentModalData, setCurrentModalData] = useState<any>({})
    const [modalName, setModalName] = useState("")
    const [currentDepartmentdata, setCurrentDepartmentData] = useState<any>({})
    const [modalStatus, setModalStatus] = useState<boolean>(false)
    const [teacherList, setAllTeacherList] = useState<any>(teacherData)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [currentTeacherData, setCurrentTeacherData] = useState<any>({})
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [uploadFiles, setUploadFiles] = useState<any>({})
    const [dropdownKey, setDropdownKey] = useState<number>(0)
    const [importModal, setImportModal] = useState<boolean>(false)
    const [configModal, setConfigModal] = useState<boolean>(false)
    const [generateModal, setGenerateModal] = useState<boolean>(false)
    const [copied, setCopied] = useState<boolean>(false);
    const [isAi, setisAi] = useState<boolean>(false)
    const [configArray, setConfigArray] = useState<configInterface[]>([initialConfigData])
    const [currentConfig, setCurrentConfig] = useState<number>(0)
    const [questionError, setQuestionError] = useState<boolean>(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const checkFilledValidation = (data: configInterface) => {
        return (
            data.subjectId !== null &&
            data.interval !== null &&
            data.date !== "" &&
            data.timeStamp !== "" &&
            data.numQuestions &&
            data.testTime !== null &&
            data.correct !== null &&
            data.correct !== "" &&
            data.wrong !== null &&
            data.wrong !== "" &&
            (data.easy !== null ||
                data.medium !== null ||
                data.hard !== null)
        );
    }

    const { handleChange, resetForm, handleSubmit, setFieldValue, setFieldTouched, handleBlur, values, errors, touched } = useFormik({
        initialValues: initialClass,
        validationSchema: classSchema,
        onSubmit: () => submitClass(),
    });
    const { handleChange: handleChange2, resetForm: resetForm2, setFieldTouched: setFieldTouched2, handleSubmit: handleSubmit2, setFieldValue: setFieldValue2, handleBlur: handleBlur2, values: values2, errors: errors2, touched: touched2 } = useFormik({
        initialValues: initialDepartment,
        validationSchema: departmentSchema,
        onSubmit: () => submitDepartment(),
    });
    const { handleChange: handleChangeTeacher, setFieldTouched: setFieldTouchedTeacher, resetForm: resetFormTeacher, handleSubmit: handleSubmitTeacher, setFieldValue: setFieldValueTeacher, handleBlur: handleBlurTeacher, values: valuesTeacher, errors: errorsTeacher, touched: touchedTeacher } = useFormik({
        initialValues: initialTeacher,
        validationSchema: teacherSchema,
        onSubmit: () => submitTeacher(),
    });

    const settingFeildValue = (name: string, value: any) => {
        setFieldValue(name, value, false)
        setTimeout(() => setFieldTouched(name, true, false));
    }
    const settingFeildValue2 = (name: string, value: any) => {
        setFieldValue2(name, value, false)
        setTimeout(() => setFieldTouched2(name, true, false));
    }
    const settingFeildValueTeacher = (name: string, value: any) => {
        setFieldValueTeacher(name, value, false)
        setTimeout(() => setFieldTouchedTeacher(name, true, false));
    }

    useEffect(() => {
        dispatch(getTopics())
    }, [])
    useEffect(() => {
        if (getTopicData) {
            setSubjectData(convertSubjectData(getTopicData))
        }
    }, [getTopicData])

    const submitTeacher = async () => {
        const userData = {
            roleId: 2,
            firstName: valuesTeacher.firstName,
            middleName: valuesTeacher.middleName,
            lastName: valuesTeacher.lastName,
            dateOfBirth: timeFormat(valuesTeacher.dateOfBirth),
            personalAddress: "NA",
            userId: currentTeacherData?.userId,
            phoneNumber: (valuesTeacher.contactNumber).toString(),
            emailId: valuesTeacher.email,
            userAccountStatus: 1,
            classId: null
        }
        if (modalName === "Edit Teacher") {
            dispatch(updateUserprofile({ data: userData, classId: null }));
        } else if (modalName === "Add Teacher") {
            const response = await postAPiCall("Login/Register", userData)
            if (response.status === 200) {
                Swal.fire({
                    html: `<div class='show_user_pass'>
                            <p>Teacher has been added successfully</p>
                            <ul class='username_pass'>
                                <li>
                                    <span>Username </span>- ${response.data?.username}
                                </li>
                                <li>
                                    <span>Password </span>- ${response.data?.password}
                                </li>
                            </ul>
                        </div>`,
                    text: "",
                    icon: "success"
                });
                dispatch(getTeacherCourses())
            }
        }
        resetFormTeacher()
        setModalOpen(false)
        setCurrentTeacherData({})
    }
    const submitClass = () => {
        const updateData = {
            classId: currentModalData.classId,
            classGrade: values.classGrade,
            section: values.section,
            displayName: values.displayName,
            classSupervisorId: currentModalData.classSupervisorId,
            departmentId: currentModalData.departmentId
        }


        configArray.forEach((item: any) => {
            if ((item.selectedChapter?.length === item.chapterData?.lengtth) || !item.selectedChapter?.length) {
                item.chapter = item.selectedChapter?.map((chapter: any) => chapter.id)
            } else {
                item.chapter = null
            }
            if ((item.selectedTopic?.length === item.topicData?.lengtth) || !item.selectedTopic?.length) {
                item.topic = item.selectedTopic?.map((chapter: any) => chapter.id)
            } else {
                item.topic = null
            }

        });
        const configData = configArray?.map(({ selectedSub, selectedChapter, availablity, selectedTopic, timeStamp, date, chapterData, topicData, ...rest }) => {
            return {
                ...rest,
            };
        });

        const vaidData = configData.filter((item: any) => checkFilledValidation(item))

        const addData = {
            classGrade: values.classGrade,
            section: values.section,
            displayName: values.displayName,
            departmentId: currentDepartmentdata.departmentId,
            config: vaidData.length ? JSON.stringify(vaidData) : null
        }
        if (modalName === "Add Class") {
            dispatch(addClass(addData))
        } else {
            dispatch(editClass(updateData))
        }
        resetForm()
        handleClose()
        setCurrentModalData({})
    }
    const submitDepartment = () => {
        const addData = {
            deptName: values2.deptName,
            deptCode: values2.deptCode,
        }
        const updateData = {
            deptName: values2.deptName,
            deptCode: values2.deptCode,
            departmentId: currentModalData.departmentId,
        }
        if (modalName === "Edit Department") {
            dispatch(editDepartment(updateData))
        } else {
            dispatch(addDepartment(addData))
        }

        resetForm2()
        handleClose()
    }
    const tabChange = (name: string, tab: number) => {
        setActiveTab(name)
        setCurrenttab(tab)
    }
    const handleSwipe = (val: number) => {
        setCurrenttab(val)
        if (val === 0) {
            setActiveTab("Departments")
        } else {
            setActiveTab("Teachers")
        }
    }
    useEffect(() => {
        setDepartmentData(departmentData)
    }, [departmentData])
    useEffect(() => {
        setAllTeacherList(teacherData)
    }, [teacherData])

    useEffect(() => {
        dispatch(getTeacherCourses())
    }, [])
    const editClick = (item: any, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setModalName("Edit Class")
        setCurrentModalData(item)
        settingFeildValue("classGrade", item.classGrade)
        settingFeildValue("section", item.section)
        settingFeildValue("displayName", item.displayName)
        if (item.config) {
            const data = JSON.parse(item.config)
            data.map((item: any) => {
                const sData = subjectData.filter((val: any) => val.id === item.subjectId)
                item.selectedSub = sData[0]
                const selectedChapter = sData[0].data.map((chapter: any) => {
                    if (item.chapter?.includes(chapter.id)) {
                        return { ...chapter, selected: true };
                    }
                    return chapter;
                })

                const selectedData = selectedChapter
                    .filter((subject: any) => subject.selected) // Filter for selected subjects
                    .flatMap((subject: any) => subject.data);    // Extract the data arrays and flatten them

                const updatedSelectedData = selectedData.map((chapter: any) => {
                    if (item.topic?.includes(chapter.id)) {
                        return { ...chapter, selected: true };
                    }
                    return chapter;
                })
                item.chapterData = selectedChapter
                item.topicData = updatedSelectedData
                item.date = timestampToDateandTime(item.time).date
                item.timeStamp = timestampToDateandTime(item.time).time
                item.timeStamp = item.timeStamp ? dayjs(item.timeStamp.toUpperCase(), 'hh:mm:ss A').format('HH:mm') : item.timeStamp;
                item.topicData = updatedSelectedData
                item.date = item.date ? dayjs(item.date, 'DD/MM/YYYY').format('YYYY-MM-DD') : item.date;
                return item
            })
            setConfigArray([...data])

        }
        setClassModal(true)
    }
    const handleClose = () => {
        setClassModal(false)
        setDepartmentModal(false)
        resetForm()
        setDeleteModal(false)
        resetForm2()
        setModalStatus(false)
        setImportModal(false)
        setModalName("")
        setModalOpen(false)
        setCurrentModalData({})
        setGenerateModal(false)
        setConfigArray([initialConfigData])
    }
    const handleCloseConfig = () => {
        setConfigModal(false)
    }
    const addClassClick = (item: any) => {
        setCurrentDepartmentData(item)
        setClassModal(true)
        setModalName("Add Class")
        setConfigArray([initialConfigData])
    }
    const addDepartmentClick = () => {
        setModalName("Add Department")
        setDepartmentModal(true)
    }

    const submitform = (e: any) => {
        e.preventDefault()
        handleSubmit()
    }
    const submitform2 = (e: any) => {
        e.preventDefault()
        handleSubmit2()
    }
    const deleteClassClick = (item: any, name: string, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setModalStatus(true)
        setModalName(name)
        setCurrentModalData(item)
    }
    const deleteDepartmentClick = (item: any, name: string) => {
        if (item.classes.length) {
            openNotification("topRight", "Deletion failed: Please remove all classes from this department first.", "", "info")
        } else {
            setModalStatus(true)
            setModalName(name)
            setCurrentModalData(item)
        }
    }
    const submitDeleteClass = () => {
        if (modalName === "Delete Class") {
            dispatch(deleteClass({ classId: currentModalData.classId }))
        } else if (modalName === "Delete Department") {
            dispatch(deleteDepartment({ departmentId: currentModalData.departmentId }))
        }
        handleClose()
        setCurrentModalData({})
    }

    const editDepartmentClick = (item: any, name: string) => {
        setModalName(name)
        setCurrentModalData(item)
        settingFeildValue2("deptName", item.deptName)
        settingFeildValue2("deptCode", item.deptCode)
        setDepartmentModal(true)
    }
    const returnModalData = () => {
        if (modalName === "Delete Class") {
            return deleteClassModalData
        } else if (modalName === "Delete Department") {
            return deleteDepartmentModalData
        }
    }
    const teacherEdit = (item: any, name: string) => {
        setCurrentTeacherData(item)
        setModalName("Edit Teacher")
        settingFeildValueTeacher("firstName", item.firstName)
        settingFeildValueTeacher("middleName", item.middleName)
        settingFeildValueTeacher("lastName", item.lastName)
        settingFeildValueTeacher("contactNumber", item.phoneNumber)
        settingFeildValueTeacher("email", item.emailId)
        settingFeildValueTeacher("dateOfBirth", item.dob)
        setModalOpen(true)
    }

    const teacherAddClick = () => {
        setModalName("Add Teacher")
        setModalOpen(true)
    }
    const onDOBChange = (value: any) => {
        let dob = moment(value).format("YYYY-MM-DD");
        settingFeildValueTeacher("dateOfBirth", dob)
    };

    const onContactNumberChange = (
        event: any,
    ) => {
        const inputValue = event.target.value;
        if (inputValue !== undefined) {
            const numericValue = inputValue.replace(/[^0-9]/g, '');
            if (numericValue.length <= 10) {
                settingFeildValueTeacher("contactNumber", numericValue)
            }
        }
    };

    const submitTeacherClick = (e: any) => {
        e.preventDefault()
        handleSubmitTeacher()
    }

    const bodyClick = (item: any, e: React.MouseEvent<HTMLElement>) => {
        navigate(`/student-manager/${encryption(item.classId)}`)
    }

    const deleteClick = (item: any, name: string) => {
        setModalName("Delete Teacher")
        setCurrentTeacherData(item)
        setDeleteModal(true)
    }
    const deleteTeacher = () => {
        dispatch(deleteUser({ userId: currentTeacherData.userId, classId: null }))
        handleClose()
    }
    const importClick = () => {
        setModalName("Import Student")
        setImportModal(true)
    }
    const fileChange = (e: any) => {
        setUploadFiles(e.target.files[0])
    };
    const importStudents = async () => {
        const formData = new FormData()
        formData.append("file", uploadFiles)
        dispatch(actionLoader({ spinnerStatus: true }))
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Login/BulkRegister?roleId=${2}&classId=${""}`, {
                method: "POST",
                headers: {
                    Authorization: `${token}`
                },
                body: formData,
            })
            if (response.ok) {
                dispatch(getTeacherCourses())
                openNotification("topRight", "Document Uploaded Successfully.", "Success")
            } else {
                openNotification("topRight", "Import Failed. Please verify all the data entered are as per format.", "error")
            }
            handleClose()
            dispatch(actionLoader({ spinnerStatus: false }))
        } catch (error) {
            openNotification("topRight", "Import Failed. Please verify all the data entered are as per format.", "error")
            dispatch(actionLoader({ spinnerStatus: false }))
        } finally {
            dispatch(actionLoader({ spinnerStatus: false }))
            handleClose()
        }
    };

    const generateClick = () => {
        setModalName("generateLink")
        setCopied(false)
        setGenerateModal(true)
    }
    const GenerateModalData = () => {
        return (
            <>
                <ClipboardModal {...{ setCopied, copied }} />
            </>
        )
    }

    const sectionChange = (e: any) => {
        setisAi(e.target.checked)
        if (e.target.checked) {
            setFieldValue("section", "AI")
        }
    }
    useEffect(() => {
        if (values.section.toLowerCase() === "ai") {
            setisAi(true)
        } else {
            setisAi(false)
        }
    }, [values.section])
    const MyCustomBlurHandler = (e: any) => {
        if (values.section.toLowerCase() === "ai") {
            setisAi(true)
            setFieldValue("section", "AI")
        }
        handleBlur(e)
    }
    const configClick = () => {
        setConfigModal(true)
        if (!configArray.length) {
            const data = JSON.parse(JSON.stringify(initialConfigData))
            setConfigArray([data])
        }
        setCurrentConfig(0)
    }

    const subjectChange = (e: any, val: any) => {
        configArray[currentConfig].selectedSub = val
        configArray[currentConfig].subjectId = val ? val.id : null; 
        configArray[currentConfig].chapterData = val ? val.data : [];
        configArray[currentConfig].selectedChapter = []
        configArray[currentConfig].topicData = []
        configArray[currentConfig].selectedTopic = []
        setConfigArray([...configArray])
        setDropdownKey(dropdownKey + 1)
    }
    const saveTest = () => {
        const data = configArray.filter((item: any) => checkFilledValidation(item))
        if (data.length === configArray.length) {
            configArray.forEach((item: any) => {
                item.chapter = item.selectedChapter?.map((chapter: any) => chapter.id)
                item.topic = item.selectedTopic?.map((topic: any) => topic.id)
            });
            const configData = configArray.map(({ selectedSub, timeStamp, date, selectedChapter, selectedTopic, chapterData, topicData, availablity, ...rest }) => {
                return {
                    ...rest,
                };
            });

            currentModalData.classId && dispatch(editConfig({
                "classId": currentModalData.classId,
                "config": JSON.stringify(configData)
            }))
            setConfigModal(false)
        } else {
            Swal.fire({
                text: `Your test has some missing data. If you save it without filling in those details, it will be removed.`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#b3b2b2",
                confirmButtonText: "Yes",
                title: "Are you sure, to save this test?",
            }).then((result) => {
                if (result.isConfirmed) {
                    setConfigArray([...data])
                    setConfigModal(false)
                }
            });
        }
    }

    const closeConfig = () => {
        setConfigModal(false)
    }

    const configChange = (e: any, name: string) => {
        const regex = /^\d*$/; // Regex to allow only numbers and empty string
        if (regex.test(e) || e === "") {
            if (name === "interval") {
                configArray[currentConfig].interval = Number(e)
                setConfigArray([...configArray])
                setInterval(e)
            } else if (name === "testTime") {
                configArray[currentConfig].testTime = Number(e)
                setConfigArray([...configArray])
            } else if (name === "numQuestions") {
                if (configArray[currentConfig]?.availablity && Object.keys(configArray[currentConfig].availablity).length) {
                    if (e <= (configArray[currentConfig].availablity.Easy + configArray[currentConfig].availablity.Medium + configArray[currentConfig].availablity.Hard)) {
                        configArray[currentConfig].easy = 0
                        configArray[currentConfig].medium = 0
                        configArray[currentConfig].hard = 0
                        configArray[currentConfig].numQuestions = Number(e)
                        setConfigArray([...configArray])
                    }
                } else {
                    setQuestionError(true)
                }
            } else if (name === "correct") {
                configArray[currentConfig].correct = Number(e)
                setConfigArray([...configArray])
            } else if (name === "wrong") {
                configArray[currentConfig].wrong = Number(e)
                setConfigArray([...configArray])
            }
        }
    }

    type ConfigKeys = 'easy' | 'medium' | 'hard';


    const conFigSliderChange = (e: any, val: any, data: any, name: ConfigKeys) => {
        const totalQuestions = configArray[currentConfig].numQuestions;

        const availableEasy = configArray[currentConfig].availablity?.Easy;
        const availableMedium = configArray[currentConfig].availablity?.Medium;
        const availableHard = configArray[currentConfig].availablity?.Hard;

        if (name === "easy") {
            configArray[currentConfig].easy = Math.min(val, availableEasy);
        }
        else if (name === "medium") {
            configArray[currentConfig].medium = Math.min(val, availableMedium);
        }
        else if (name === "hard") {
            configArray[currentConfig].hard = Math.min(val, availableHard);
        }

        const selectedEasy = configArray[currentConfig].easy;
        const selectedMedium = configArray[currentConfig].medium;
        const selectedHard = configArray[currentConfig].hard;

        const totalSelected = selectedEasy + selectedMedium + selectedHard;

        if (totalSelected > totalQuestions) {
            if (name === "easy") {
                configArray[currentConfig].easy = Math.max(0, totalQuestions - selectedMedium - selectedHard);
            } else if (name === "medium") {
                configArray[currentConfig].medium = Math.max(0, totalQuestions - selectedEasy - selectedHard);
            } else if (name === "hard") {
                configArray[currentConfig].hard = Math.max(0, totalQuestions - selectedEasy - selectedMedium);
            }
        }

        setConfigArray([...configArray]);
    };
    const timeChange = (e: any, timeString: any) => {
        configArray[currentConfig].timeStamp = timeString
        configArray[currentConfig].time = dandTInTimestamp(`${configArray[currentConfig].date}T${timeString}:00`)
        setConfigArray([...configArray])
    }

    const subjectClick: any = (event: any, index: number, item: any) => {
        setCurrentConfig(index)
        setQuestionError(false)
        setDropdownKey(dropdownKey + 1)

    }

    const addTestClick = () => {
        setCurrentConfig(configArray.length)
        const newData = JSON.parse(JSON.stringify(defaultConfigData))
        configArray.push(newData)
        setConfigArray([...configArray])
    }

    const selectDate = (e: any, timeString: any) => {
        configArray[currentConfig].date = timeString
        configArray[currentConfig].time = dandTInTimestamp(`${timeString}T${configArray[currentConfig].timeStamp}:00`)
        setConfigArray([...configArray])
    }

    const chapterChange = (e: any, val: any) => {
        configArray[currentConfig].selectedChapter = [...val]
        const topicValue = val.map((item: any) => item.data).flat()
        const topicName = configArray[currentConfig].selectedTopic?.map((item: any) => item.name)
        const selectedTopicValue = topicValue.filter((item: any) => topicName?.includes(item.name))
        configArray[currentConfig].topicData = [...topicValue]
        configArray[currentConfig].selectedTopic = [...selectedTopicValue]
        setConfigArray([...configArray])
    }
    const topicChange = (e: any, val: any) => {
        configArray[currentConfig].selectedTopic = [...val]
        setConfigArray([...configArray])
    }

    const seeAvailable = async () => {
        const topicId = configArray[currentConfig].selectedTopic?.map((item: any) => item.id)
        const chapterIdId = configArray[currentConfig].selectedChapter?.map((item: any) => item.id)
        const subjectIds = configArray[currentConfig].selectedSub.id
        const payloadData = {
            subjectLst: [subjectIds]?.length ? [subjectIds] : null,
            chapterLst: chapterIdId?.length ? chapterIdId : null,
            topicLst: topicId?.length ? topicId : null
        }
        const response = await postAPiCall("CreatorStudios/GetAvailablePages", payloadData)
        if (response.data) {
            setQuestionError(false)
            configArray[currentConfig].availablity = response.data
            setConfigArray([...configArray])
        } else {
            setQuestionError(false)
            configArray[currentConfig].availablity = {}
            setConfigArray([...configArray])

        }

    }

    const selectAllChapter = (e: any) => {
        if (e.target.checked) {
            configArray[currentConfig].selectedChapter = configArray[currentConfig].chapterData
            configArray[currentConfig].topicData = configArray[currentConfig].chapterData.map((item: any) => item.data).flat()
            setConfigArray([...configArray])
        } else {
            configArray[currentConfig].selectedChapter = []
            configArray[currentConfig].topicData = []
            configArray[currentConfig].selectedTopic = []
            setConfigArray([...configArray])
        }
    }
    const selectAllTopic = (e: any) => {
        if (e.target.checked) {
            configArray[currentConfig].selectedTopic = configArray[currentConfig].topicData
            setConfigArray([...configArray])
        } else {
            configArray[currentConfig].selectedTopic = []
            setConfigArray([...configArray])
        }
    }

    const setMaxNumber = (name: string) => {
        if (configArray[currentConfig]?.availablity && Object.keys(configArray[currentConfig]?.availablity).length) {
            if (configArray[currentConfig]?.availablity[name] < configArray[currentConfig]?.numQuestions) {
                return configArray[currentConfig]?.availablity[name]
            } else {
                return configArray[currentConfig]?.numQuestions
            }
        } else {
            return 0
        }

    }


    return (
        <>
            <CommonModal modalOpen={classModal} handleClose={handleClose} className="add_course_modal">
                <form onSubmit={submitform} className="addCourseBody">
                    <p className="modal_heading">{modalName}</p>
                    <TextField
                        label="Class Grade"
                        placeholder="Class Grade"
                        className="loginField"
                        style={{marginTop:0}}
                        name='classGrade'
                        required
                        value={values.classGrade}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={(touched.classGrade && errors.classGrade) ? errors.classGrade : ""}
                    />
                    <div className='section_field' style={{ display: "flex" }}>
                        <TextField
                            label="Section"
                            placeholder="Section"
                            className="loginField"
                            name='section'
                            style={{marginTop:0}}
                            required
                            disabled={isAi}
                            value={values.section}
                            onChange={handleChange}
                            onBlur={MyCustomBlurHandler}
                            errorMessage={(touched.section && errors.section) ? errors.section : ""}
                        />
                        <div className='ai_checkox' >
                            {isAi ? <Menu onClick={configClick} className='configIcon' /> : ""}
                            <input id='ai_check' checked={isAi} type="checkbox" onChange={(e: any) => sectionChange(e)} />
                            <label htmlFor="ai_check">Make this AI enabled</label>
                        </div>
                    </div>
                    <TextField
                        label="Display Name"
                        placeholder="Display Name"
                        className="loginField"
                        name='displayName'
                        style={{marginTop:0}}
                        required
                        value={values.displayName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={(touched.displayName && errors.displayName) ? errors.displayName : ""}
                    />
                    <div className="modal_footer_btn">
                        <Button types='submitform'  className='modal_ok_btn' btnText="Save Class" />
                        <Button onClick={handleClose} className='modal_cancel_btn' btnText="Cancel" />
                    </div>
                </form>
            </CommonModal>
            <CommonModal modalOpen={departmentModal} handleClose={handleClose} className="add_course_modal">
                <form onSubmit={submitform2} className="addCourseBody">
                    <p className="modal_heading">{modalName}</p>
                    <TextField
                        label="Department Name"
                        placeholder="Department Name"
                        className="loginField"
                        name='deptName'
                        required
                        value={values2.deptName}
                        onChange={handleChange2}
                        onBlur={handleBlur2}
                        errorMessage={(touched2.deptName && errors2.deptName) ? errors2.deptName : ""}
                    />
                    <TextField
                        label="Department Code"
                        placeholder="Department Code"
                        className="loginField"
                        name='deptCode'
                        required
                        value={values2.deptCode}
                        onChange={handleChange2}
                        onBlur={handleBlur2}
                        errorMessage={(touched2.deptCode && errors2.deptCode) ? errors2.deptCode : ""}
                    />
                    <div className="modal_footer_btn">
                        <Button types='submit'  className='modal_ok_btn' btnText={modalName === "Add Department" ? "Create Department" : "Update Department"} />
                        <Button onClick={handleClose} className='modal_cancel_btn' btnText="Cancel" />
                    </div>
                </form>
            </CommonModal>
            <CommonModal modalOpen={modalStatus} handleClose={handleClose} className="add_course_modal">
                <div className="addCourseBody">
                    <p className="modal_heading">{modalName}</p>
                    {returnModalData()}
                    <div className="modal_footer_btn">
                        <Button onClick={submitDeleteClass} className='modal_ok_btn' btnText="Yes" />
                        <Button onClick={handleClose} className='modal_cancel_btn' btnText="No" />
                    </div>
                </div>
            </CommonModal>
            <CommonModal modalOpen={modalOpen} handleClose={handleClose} className="add_course_modal teacher_modal">
                <form onSubmit={submitTeacherClick} className="addDepartmentForm">
                    <TextField
                        label="First Name"
                        placeholder="First Name"
                        className="loginField"
                        name='firstName'
                        required
                        value={valuesTeacher.firstName}
                        onChange={handleChangeTeacher}
                        onBlur={handleBlurTeacher}
                        errorMessage={(touchedTeacher.firstName && errorsTeacher.firstName) ? errorsTeacher.firstName : ""}
                    />
                    <TextField
                        label="Middle Name"
                        placeholder="Middle Name"
                        className="loginField"
                        name='middleName'
                        value={valuesTeacher.middleName}
                        onChange={handleChangeTeacher}
                        onBlur={handleBlurTeacher}
                        errorMessage={(touchedTeacher.middleName && errorsTeacher.middleName) ? errorsTeacher.middleName : ""}
                    />
                    <TextField
                        label="Last Name"
                        placeholder="Last Name"
                        className="loginField"
                        name='lastName'
                        required
                        value={valuesTeacher.lastName}
                        onChange={handleChangeTeacher}
                        onBlur={handleBlurTeacher}
                        errorMessage={(touchedTeacher.lastName && errorsTeacher.lastName) ? errorsTeacher.lastName : ""}
                    />

                    <TextField
                        label="Contact number"
                        placeholder="Contact number"
                        className="loginField"
                        name='contactNumber'
                        onBlur={handleBlurTeacher}
                        errorMessage={(touchedTeacher.contactNumber && errorsTeacher.contactNumber) ? errorsTeacher.contactNumber : ""}
                        required
                        value={valuesTeacher.contactNumber}
                        onChange={onContactNumberChange}
                    />



                    <TextField
                        label="Email"
                        placeholder="Email"
                        className="loginField"
                        name='email'
                        required
                        value={valuesTeacher.email}
                        onChange={handleChangeTeacher}
                        onBlur={handleBlurTeacher}
                        errorMessage={(touchedTeacher.email && errorsTeacher.email) ? errorsTeacher.email : ""}
                    />
                    <DatePicker
                        isRequired
                        label="Date of birth"
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={valuesTeacher.dateOfBirth ? new Date(valuesTeacher.dateOfBirth) : new Date()}
                        strings={defaultDatePickerStrings}
                        onSelectDate={onDOBChange}
                    />
                    <div className="modal_footer_btn">
                        <Button types='submit'  className='modal_ok_btn' btnText="Save" />
                        <Button onClick={handleClose} className='modal_cancel_btn' btnText="No" />
                    </div>
                </form>
            </CommonModal>
            <CommonModal modalOpen={deleteModal} handleClose={handleClose} className="add_course_modal teacher_modal">
                <p className="modal_heading">{modalName}</p>
                <p>Are you sure you want to delete? This action can not be undone.</p>
                <div className="modal_footer_btn">
                    <Button onClick={deleteTeacher} className='modal_ok_btn' btnText="Delete" />
                    <Button onClick={handleClose} className='modal_cancel_btn' btnText="Cancel" />
                </div>
            </CommonModal>
            <CommonModal modalOpen={importModal} handleClose={handleClose} className="add_course_modal teacher_modal">
                <ImportModalData {...{ fileChange, uploadFiles, importStudents }} />
            </CommonModal>
            <CommonModal modalOpen={generateModal} handleClose={handleClose} className="add_course_modal teacher_modal">
                <GenerateModalData />
            </CommonModal>
            <CommonModal modalOpen={configModal} handleClose={handleCloseConfig} className="add_course_modal teacher_modal config_modal"
                BackdropProps={{
                    onClick: null as any,
                }}
            >
                <>
                    <ConfigModal {...{
                        configArray, currentConfig, subjectClick, subjectData, addTestClick,
                        subjectChange, topicChange, chapterChange, seeAvailable, configChange, selectAllChapter, selectAllTopic, questionError,
                        conFigSliderChange, setMaxNumber, selectDate, timeChange, closeConfig
                    }} saveClick={saveTest} />
                </>
            </CommonModal >
            <div className="homeworkElementsContainer">
                <div className="dtbadge">
                    <span
                        className={activeTab === "Departments" ? "activeBadge" : "badge"}
                        onClick={() => tabChange("Departments", 0)}
                    >
                        Departments
                    </span>
                    <span
                        className={activeTab === "Teachers" ? "activeBadge" : "badge"}
                        onClick={() => tabChange("Teachers", 1)}
                    >
                        Teachers
                    </span>
                </div>
                <SwipeableViews className="swipeParent" index={currentTab} enableMouseEvents onChangeIndex={handleSwipe}>
                    <div><DepartmentBody {...{ addClassClick, addDepartmentClick, departmentCourseData, editClick, editStatus, deleteClassClick, editDepartmentClick, deleteDepartmentClick, bodyClick }} /> </div>
                    <div><TeacherBody {...{ handleClose, modalOpen, teacherEdit, teacherList, teacherAddClick, deleteClick, importClick, generateClick }} name="Teacher" /></div>
                </SwipeableViews>
            </div>
        </>
    );
};

export default DtManager;

