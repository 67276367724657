import { TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import StudentTeacherList from '../dtmanager/dtManagerComp/studentTeacherList';
import { useFormik } from 'formik';
import { initialTeacher } from '../../utils/formikInitial';
import { teacherSchema } from '../../utils/validationSchema';
import CommonModal from '../../components/commonModal/CommonModal';
import {
    DatePicker,
    defaultDatePickerStrings,
} from "@fluentui/react/lib/DatePicker";
import Button from '../../components/Common/Button/Button';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getStudentsByClass } from '../../redux/actions/API/teacherCourses';
import { decryption, openNotification, timeFormat } from '../../utils/CommonFunction';
import { updateUserprofile } from '../../redux/actions/API/courses';
import { actionLoader } from '../../redux/actions/UI/spinnerStatus';
import ImportModalData from '../../components/Common/ImportModalData';
import ClipboardModal from '../../components/Common/clipboardModal';
import { fetchPost } from '../../API/Baseurl';
import "./index.css"
import Swal from 'sweetalert2'
import ClipboardPaidModal from '../../components/Common/clipboardPaidModal';

interface StudentManagerProps {
    // Define your props here
}

const StudentManager: React.FC<StudentManagerProps> = (props) => {
    const getStudentsByClassData = useSelector((state: any) => state.api.getStudentsByClass?.data)
    const token = useSelector((state: any) => state.api.login.data.token)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [studentData, setStudetData] = useState<any[]>(getStudentsByClassData)
    const [currentStudent, setCurrentStudent] = useState<any>({})
    const [modalName, setModalName] = useState<string>("")
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [uploadFiles, setUploadFiles] = useState<any>({})
    const [copied, setCopied] = useState<boolean>(false);
    const [userIdPass, setUserIdPass] = useState<any>({})

    const param = useParams()
    const dispatch = useDispatch()

    const classId = decryption(param.id)

    const { handleChange, setFieldTouched, resetForm, handleSubmit, setFieldValue, handleBlur, values, errors, touched } = useFormik({
        initialValues: initialTeacher,
        validationSchema: teacherSchema,
        onSubmit: () => submitTeacher(),
    });

    useEffect(() => {
        setStudetData(getStudentsByClassData)
    }, [getStudentsByClassData])

    const settingFeildValue = (name: string, value: any) => {
        setFieldValue(name, value, false)
        setTimeout(() => setFieldTouched(name, true, false));
    }


    const UsernameModal = () => {
        return (
            <div className='show_user_pass'>
                <p>Student has been added successfully</p>
                <ul className='username_pass'>
                    <li>
                        <span>Username </span>- {userIdPass?.username}
                    </li>
                    <li>
                        <span>Password </span>- {userIdPass?.password}
                    </li>
                </ul>
            </div>
        )
    }
    const submitTeacher = async () => {
        const userData = {
            roleId: 3,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            dateOfBirth: timeFormat(values.dateOfBirth),
            personalAddress: "NA",
            userId: currentStudent?.userId,
            phoneNumber: values.contactNumber,
            emailId: values.email,
            userAccountStatus: 1,
            payment: "",
            classId: (classId ? +classId : null)
        }

        if (modalName === "Edit Student") {
            // dispatch(updateUserprofile({ data: userData, classId: (classId ? +classId : null) }));
            const response = await fetchPost("Login/EditUser", userData)
            if (!response.errorMessage) {
                handleClose()
                dispatch(getStudentsByClass({ classId: (classId ? +classId : null) }))
            }
        } else if (modalName === "Add Student") {
            const response = await fetchPost("Login/Register", userData)
            if (!response.errorMessage) {
                handleClose()
                dispatch(getStudentsByClass({ classId: (classId ? +classId : null) }))
                setUserIdPass(response)
                Swal.fire({
                    // title: <UsernameModal />,
                    html: `<div class='show_user_pass'>
                            <p>Student has been added successfully</p>
                            <ul class='username_pass'>
                                <li>
                                    <span>Username </span>- ${response?.username}
                                </li>
                                <li>
                                    <span>Password </span>- ${response?.password}
                                </li>
                            </ul>
                        </div>`,
                    text: "",
                    icon: "success"
                });

                setSubmitted(true)
            } else {
                openNotification("topRight", response.errorMessage, "Error", "error")

            }
        }

    }

    const onContactNumberChange = (
        event: any,
    ) => {
        const inputValue = event.target.value;
        if (inputValue !== undefined) {
            const numericValue = inputValue.replace(/[^0-9]/g, '');
            if (numericValue.length <= 10) {
                settingFeildValue("contactNumber", numericValue)
            }
        }
    };

    const onDOBChange = (value: any) => {
        let dob = moment(value).format("YYYY-MM-DD");
        settingFeildValue("dateOfBirth", dob)
    };

    const teacherEdit = (item: any, name: string) => {
        setModalName("Edit Student")
        setCurrentStudent(item)
        settingFeildValue("firstName", item.firstName)
        settingFeildValue("middleName", item.middleName)
        settingFeildValue("lastName", item.lastName)
        settingFeildValue("contactNumber", item.phoneNumber)
        settingFeildValue("email", item.emailId)
        settingFeildValue("dateOfBirth", item.dob)
        setModalOpen(true)
    }
    const handleClose = () => {
        setModalOpen(false)
        setDeleteModal(false)
        setModalName("")
        setUploadFiles({})
        resetForm()
        setCurrentStudent({})
    }
    const teacherAddClick = () => {
        setModalName("Add Student")
        setModalOpen(true)
    }
    const generateClick = () => {
        setModalName("generateLink")
        setCopied(false)
        setDeleteModal(true)
    }
    const generatePaidClick = () => {
        setModalName("generatePaidClick")
        setCopied(false)
        setDeleteModal(true)
    }

    const submitStudentsClick = (e: any) => {
        e.preventDefault()
        handleSubmit()
    }

    useEffect(() => {
        dispatch(getStudentsByClass({ classId: (classId ? +classId : null) }))
    }, [])

    const deleteClick = (item: any, name: string) => {
        setModalName("Delete Student")
        setCurrentStudent(item)
        setDeleteModal(true)
    }

    const deleteStudent = () => {
        dispatch(deleteUser({ userId: currentStudent.userId, classId: (classId ? +classId : null) }))
        handleClose()
    }
    const importClick = () => {
        setModalName("Import Student")
        setDeleteModal(true)

    }

    const DeleteModalContent = () => {
        return (
            <>
                <p className="modal_heading">{modalName}</p>
                <p>Are you sure you want to delete? This action can not be undone.</p>
                <div className="modal_footer_btn">
                    <Button onClick={deleteStudent} className='modal_ok_btn' btnText="Delete" />
                    <Button onClick={handleClose} className='modal_cancel_btn' btnText="Cancel" />
                </div>
            </>
        )
    }

    const fileChange = (e: any) => {
        setUploadFiles(e.target.files[0])
    };

    const importStudents = async () => {
        const formData = new FormData()
        formData.append("file", uploadFiles)
        dispatch(actionLoader({ spinnerStatus: true }))
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Login/BulkRegister?roleId=${3}&classId=${(classId ? +classId : null)}`, {
                method: "POST",
                headers: {
                    Authorization: `${token}`
                },
                body: formData,
            })
            if (response.ok) {
                dispatch(getStudentsByClass({ classId: (classId ? +classId : null) }))
                openNotification("topRight", "Document Uploaded Successfully.", "Success")

            } else {
                openNotification("topRight", "Import Failed. Please verify all the data entered are as per format.", "error")
            }
            handleClose()

            dispatch(actionLoader({ spinnerStatus: false }))
        } catch (error) {
            openNotification("topRight", "Import Failed. Please verify all the data entered are as per format.", "error")
            dispatch(actionLoader({ spinnerStatus: false }))
        } finally {
            dispatch(actionLoader({ spinnerStatus: false }))
            handleClose()
        }

    };
    const GenerateModalData = () => {
        const data: any = param.id;
        // const encodedData = btoa(data)

        return (
            <ClipboardModal {...{ setCopied, copied }} encodedData={data} />
        )
    }
    const GeneratePaidModalData = () => {
        const data: any = param.id;
        return (
            <ClipboardPaidModal encodedData={data} {...{ setCopied, copied }} />
        )
    }


    const modalData = () => {
        if (modalName === "Delete Student") {
            return <DeleteModalContent />
        } else if (modalName === "Import Student") {
            return <ImportModalData {...{ fileChange, uploadFiles, importStudents }} />
        }
        else if (modalName === "generateLink") {
            return <GenerateModalData />
        }
        else if (modalName === "generatePaidClick") {
            return <GeneratePaidModalData />
        }
        else if (modalName === "username") {
            return <UsernameModal />
        }

    }

    return (
        <>
            <CommonModal modalOpen={modalOpen} handleClose={handleClose} className="add_course_modal teacher_modal">
                <p className="modal_heading">{modalName}</p>

                <div className="addDepartmentForm">
                    <TextField
                        label="First Name"
                        placeholder="First Name"
                        className="addStudentField"
                        name='firstName'
                        required
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={(touched.firstName && errors.firstName) ? errors.firstName : ""}
                    />
                    <TextField
                        label="Middle Name"
                        placeholder="Middle Name"
                        className="addStudentField"
                        name='middleName'
                        value={values.middleName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={(touched.middleName && errors.middleName) ? errors.middleName : ""}
                    />
                    <TextField
                        label="Last Name"
                        placeholder="Last Name"
                        className="addStudentField"
                        name='lastName'
                        required
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={(touched.lastName && errors.lastName) ? errors.lastName : ""}
                    />
                    <TextField
                        label="Contact number"
                        placeholder="Contact number"
                        className="addStudentField"
                        name='phoneNumber'
                        onBlur={handleBlur}
                        errorMessage={(touched.contactNumber && errors.contactNumber) ? errors.contactNumber : ""}
                        required
                        value={values.contactNumber}
                        onChange={onContactNumberChange}
                    />
                    <TextField
                        label="Email"
                        placeholder="Email"
                        className="addStudentField"
                        name='email'
                        required
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={(touched.email && errors.email) ? errors.email : ""}
                    />
                    <DatePicker
                        isRequired
                        maxDate={new Date()}
                        label="Date of birth"
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={values.dateOfBirth ? new Date(values.dateOfBirth) : new Date()}
                        strings={defaultDatePickerStrings}
                        onSelectDate={onDOBChange}
                    />
                    <div className="modal_footer_btn">
                        <Button onClick={submitStudentsClick} className='modal_ok_btn' btnText="Yes" />
                        <Button onClick={handleClose} className='modal_cancel_btn' btnText="No" />
                    </div>
                </div>
            </CommonModal>
            <CommonModal modalOpen={deleteModal} handleClose={handleClose} className="add_course_modal teacher_modal">
                {modalData()}
            </CommonModal>
            <div className="homeworkElementsContainer">

                <StudentTeacherList data={studentData} classId={classId} name="Student" {...{ teacherEdit, handleClose, modalOpen, teacherAddClick, deleteClick, importClick, generateClick, generatePaidClick }} />
            </div>
        </>

    );
};

export default StudentManager;