
import React, { useState } from 'react'
import logo from "../assets/webodhi_logo.png"
import { TextField } from 'office-ui-fabric-react'
import { useDispatch } from 'react-redux'
import { forgotPassword, resetLogin } from '../redux/actions/API/login'
import { useNavigate } from 'react-router-dom'
import "./ForgotPassword.css"
import Button from './Common/Button/Button'
import { useFormik } from 'formik'
import { ForgotSchema } from '../utils/validationSchema'
import { postAPiCall } from '../API/Baseurl'
import { openNotification } from '../redux/sagas/sagas'
import { Alert } from '@mui/material'

const ForgetPassword = () => {
    const [responseData, setResponseData] = useState<any>("")
    const [error, setError] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const login = () => {
        navigate("/")
        dispatch(resetLogin())
    }
    const btnClick = () => {
        console.log("")
    }

    const Intial = {
        email: ""
    };


    const { handleChange, handleSubmit, handleBlur, values, errors, touched } = useFormik({
        initialValues: Intial,
        validationSchema: ForgotSchema,
        onSubmit: () => submitApiCall(),
    });
    const submitClick = (e: any) => {
        e.preventDefault()
        handleSubmit()
    }

    const submitApiCall = async () => {
        // dispatch(forgotPassword({ email: values.email }))
        try {
            const response: any = await postAPiCall("Login/ForgetPassword", { email: values.email })
            if (response?.data?.errorMessage) {
                openNotification("error", response?.data?.errorMessage)
                setResponseData(response?.data?.errorMessage)
                setError(true)
            } else {
                setResponseData(response?.data?.password)
                openNotification("success", "Password has been reset. Please check Email.")
                setError(false)
            }

        } catch (error) {
            openNotification("error", error)
        }
    }


    return (
        <>
            <form className='wrapper' onSubmit={submitClick}>
                <div className="form-signin">
                    <div className="logoBody">
                        <img src={logo} alt="logo"></img>
                    </div>
                    <h3 className="form-signin-heading">Forgot Password</h3>
                    {(responseData && !error) ? <>
                        <ul className='username_pass'>
                            <li>
                                <span>New Password </span>- {responseData}
                            </li>
                        </ul>
                    </> : ""}
                    {error ? <p className="error loginError">{responseData}</p> : ""}
                    <TextField
                        placeholder="Email"
                        className="loginField"
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='email'
                        errorMessage={(touched.email && errors.email) ? errors.email : ""}
                    />
                    <div className="loginSubmitBtn">
                        <Button btnText="Submit" onClick={btnClick} className="btn btn-lg btn-primary btn-block login_btn" types="submit" />
                    </div>
                    <div className='login_btn_section'>
                        <p className='login_click' onClick={login}>Login</p>
                    </div>
                    <Alert sx={{ marginTop: "10px" }} severity="info">Your username is your phone number, and your password should be your date of birth in the format YYYY-MM-DD (e.g., 2000-12-01).</Alert>
                </div>
            </form>
        </>
    )
}

export default ForgetPassword